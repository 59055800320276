
.activeorderspage{
    display: flex;
    overflow-y: hidden;
    max-height: 100vh;
}

.menusidebar{
    flex: 1;
    min-height: 100vh !important;
}

.activeOrdersFeed{
    flex: 5;
    max-height: 100vh;
    padding-bottom: 20px;
    overflow-y: scroll;
    padding-left: 15px;
    padding-right: 15px;
}

.errorMessage{
    color: red;
    font-size: 30px;
    text-align: center;

}

.loadingProgressDiv{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 10px;
    font-family: 'roboto';
}

.tableLoadingProgressDiv{
    padding-right: 20px;
    position: fixed;
}

.actionButtonsDiv{
    display: flex;
}

.actionButton{
    align-items: center;
    background-color: #fff;
    border: none;
    padding-top: 5px;
    cursor: pointer;
    margin-right: 7px;
}

.actionButton:disabled{
    opacity: 0.4;
    cursor: not-allowed;
}
.actionIcon{
    color: #1C1045;
}

.renderInfo{
    border-radius: 20px;
    background-color: #1C1045;
    font-weight: 400;
    color: #fff;
    padding: 10px;
    width: 100px;
    text-align: center;
    cursor: pointer;
}

.renderInfoButton{
    border-radius: 20px;
    background-color: #1C1045;
    font-weight: 500;
    color: #fff;
    padding: 10px;
    width: 100px;
    height: 35px;
    margin-right: 7px;
    text-align: center;
    cursor: pointer;
    border: none;
}

.renderInfoButton:disabled{
    opacity: 0.4;
    cursor: not-allowed;
}
.orderModalHeader{
    font-size: 25px;
    font-weight: 700;
    color: #1C1045;
    margin: 0;
}

/* .orderModalLabel{
    display: inline;
    padding-top: 30px;
    font-size: 18px;
    font-weight: 600;
    color: #1C1045;
} */

.modalContent{
    font-family: 'roboto'
}

.viewDiagnosisButton{
    background-color: #E56F09;
    border-radius: 20px;
    width: 170px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 18px;
    font-weight: 550;
    color: #1C1045;
    border: none;
    cursor: pointer;

}

.viewInvoiceButton{
    background-color: #E56F09;
    border-radius: 20px;
    width: 220px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 18px;
    font-weight: 550;
    color: #1C1045;
    border: none;
    cursor: pointer;

}

.viewInvoiceButton:disabled{
    cursor: not-allowed;
    opacity: 0.4;
}

.invoiceTable{
    padding: 20px 5px;
}

.invoiceActionButtons{
    background-color: #E56F09;
    border-radius: 20px;
    width: 130px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 18px;
    font-weight: 550;
    color: #1C1045;
    border: none;
    cursor: pointer;
    margin-right: 10px;
}
.invoiceSuccessMessage{
    color: #E56F09;
    padding-top: 10px;
    text-align: center;
    font-size: 20px;
}
.requestButtonDiv{
    padding: 15px 5px;
    padding-right: 30px;
    display: flex;
    flex-direction: row-reverse;
}

.deleteOrderButton{
    background-color: red;
    border-radius: 20px;
    width: 80px;
    padding-top: 7px;
    padding-bottom: 7px;
    font-size: 16px;
    font-weight: 550;
    color: #fff;
    border: none;
    cursor: pointer;

}

.deleteOrderButton:disabled{
    cursor: not-allowed;
    opacity: 0.4;
}

.orderStatusColorsDiv{
    max-width: 150px;
    min-width: 80px;
    text-align: center;
    border-radius: 15px;
    padding-top: 5px;
    padding-bottom: 5px;
    color: #fff;
}

.addDiagnosisButton{
    background-color: #fcf7f4;
    border-radius: 20px;
    width: 170px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 18px;
    font-weight: 550;
    color: #1C1045;
    border: none;
    cursor: pointer;

}

.searchBar{
    display: flex;
    justify-content: flex-end;
    padding-top: 15px;
    padding-bottom: 10px;
    padding-right: 5px;
}

.searchInput{
    font-size: 18px;
    font-family: 'roboto';
    padding-left: 5px;
    border: none;
    border-bottom: 2px solid black;
}

.searchInput:focus{
    color:  #1C1045;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    outline: none;
}

.addDiagnosisButton:disabled{
    cursor: not-allowed;
    opacity: 0.4;
}
@media all and (max-width: 700px){
    .activeOrdersFeed{
        padding-left: 15px;
        padding-right: 15px;
    }

    .requestButtonDiv{
        padding: 15px 5px;
        padding-right: 0px;
        
    }
    .tableLoadingProgressDiv{
        padding-right: 25px;
    }
}

.checkBoxesDivs{
    display: flex;
    flex-direction: column;
}
.checkBoxedDiv{
    /* display: flex; */
}

@media all and (min-width: 700px){
    .invoiceTable{
        min-width: 400px;
    }

}
