.textEditorStyles{
    border: solid 1px #f5f5f5;
    background-color: #f5f5f5;
    min-height: 400px;
    border-radius: 20px;
}

.editorTextStyle{
    padding: 10px;
}

.toolbarStyle{
    border-radius: 20px 20px 0px 0px;
}