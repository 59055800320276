.addButton{
    background-color: #E56F09;
    border-radius: 20px;
    width: 80px;
    padding-top: 4px;
    padding-bottom: 4px;
    font-size: 15px;
    font-weight: 550;
    color: #1C1045;
    border: none;
    cursor: pointer;
}

.addDiagnosisButton{
    background-color: #E56F09;
    border-radius: 20px;
    width: 80px;
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 16px;
    font-weight: 550;
    color: #1C1045;
    border: none;
    cursor: pointer;

}

.addDiagnosisButton:disabled{
    cursor: not-allowed;
    opacity: 0.4;
}


.modalSuccessMessage{
    color: #E56F09;
    padding-top: 10px;
    text-align: center;
    margin-top: 0px;
    margin-bottom: 1px;
    font-size: 15px;
}

.modalErrorMessage{
    color: red;
    padding-top: 20px;
    text-align: center;
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 15px;
}

