.leftbarmenu
{
    height: 100%;
    overflow: auto;
    color: white;
    background-color: #1C1045;
    padding-top: 15px;
    top: 0; 
    margin: 0px;
}
.menuitems{
    display: flex;
    align-items: center;
    padding-left: 20px;
    padding-right: 10px;
    padding-top: 15px;
    padding-bottom: 15px;
}
.menuitems:hover{
    background-color:  #E56F09;  
    opacity: 0.8;
    padding-right: 50px;
}

.active{
    background-color:  #E56F09;  
    opacity: 0.8;
}

#logoutButton:hover{
    background-color:  #fff;  
    opacity: 1;
}



/* Edit Modal */
.headerText{
    font-size: 20px;
}

.loadingProgressDiv{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 10px;
    font-family: 'roboto';
}

.editModalDiv{
    padding: 15px;
    padding-right: 25px;
    font-family: 'roboto';
}

.editModalInputItems{
    padding-top: 10px;
    padding-bottom: 10px;
}

.editLabels{
    padding-left: 20px;
    margin-bottom: 20px;
}
.editModalFormInput{
    width: 98%; 
    height: 20px;
    border-radius: 45px;
    border: solid 1px;
    padding-left: 20px;
    background-color: #f5f5f5;
    border: solid 1px;
    padding-top: 10px;
    margin-top: 7px;
    padding-bottom: 10px;
    padding-left: 20px;
    border: none;
    font-size: 15px;
    -webkit-appearance:none;
}

.editModalFormInput:focus{
    color:  #1C1045;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    outline: none;
}

.formSelect{
    width: 100%;
    height: 70px;
    border-radius: 45px;
    border: solid 1px;
    padding-left: 20px;
    background-color: #f5f5f5;
    border: solid 1px;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 20px;
    border: none;
    padding-right: 100px;
    font-size: 15px;
    -webkit-appearance:none;
}

.editDeviceComplainTextArea{
    padding-left: 25px;
    padding-top: 25px;
    border-radius: 45px;
    width: 98%;
    margin-top: 7px;
    border: solid 1px;
    background-color: #f5f5f5;
    resize: none;
    border: none;
    font-size: 17px;
    font-family: 'roboto';
}

.editDeviceComplainTextArea:focus{
    color: #1C1045;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    outline: none;
}

.editSubmitButton{
    background-color: #E56F09;
    border-radius: 20px;
    width: 80px;
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 15px;
    font-weight: 550;
    color: #1C1045;
    border: none;
    cursor: pointer;
}


/* End Edit Details Modal */




/* Invoice  Component */

.invoiceIconsTableCell{
    display: flex;
    flex-direction: row;
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 0px;
    /* max-width: 60px; */
}

.editInvoiceIcons{
    display: flex;
    flex: 2;
    flex-direction: column;
}

.editInvoiceRowConatiner{
    font-family: 'roboto';
}
.editInvoiceRowDiv{
    display: flex;
    flex-direction: row;
}

.editInvoiceRowItems{
    margin-right: 30px;
}
.editInvoiceRowButton{
    background-color: #E56F09;
    border-radius: 20px;
    width: 100px;
    height: 40px;
    margin-top: 30px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 18px;
    font-weight: 550;
    color: #1C1045;
    border: none;
    cursor: pointer;

}
/* End Invoice Component Modal */


.logoutMessage{
    font-size: 30px;
    font-family: 'jost';
    font-weight: 500;
    margin-top: 30px;
    text-align: center;
    color: red;
}

.logoutCheckButtons{
    display: flex;
    justify-content: center;
}
.logoutCheckButton{
    width: 80px;
    height: 40px;
    font-size: 20px;
    border-radius: 18px;
    border: none;
    color: #fff;
    font-weight: 500;
    cursor: pointer;
    margin: 0 10px;
    padding: 5px;
}

@media all and (min-width: 1500px){
    .leftbarmenu{
        height: 100vh;
    }
}

.css-1xdhyk6{
    display: flex;
}

.react-date-picker__wrapper {
    display: flex;
    flex-grow: 1;
    flex-shrink: 0;
    /* border: thin solid red; */
}

.MuiInputBase-input {
    font: inherit;
    color: currentColor;
    width: 100%;
    border: 0;
    height: 1.1876em;
    margin: 0;
    display: block;
    padding: 1px 0 2px;
    min-width: 0;
    background: none;
    box-sizing: content-box;
    animation-name: mui-auto-fill-cancel;
    letter-spacing: inherit;
    animation-duration: 10ms;
    -webkit-tap-highlight-color: transparent;
}

/* .MuiSelect-select.MuiSelect-select {
    margin-right: 24px;
} */