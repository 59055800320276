
.supportPage{
    display: flex;
    overflow-y: hidden;
    max-height: 100vh;
    font-family: 'roboto';
}

.menusidebar{
    flex: 1;
    min-height: 100vh !important;
}

.supportFeed{
    flex: 5;
    max-height: 100vh;
    overflow-y: scroll;
    padding-left: 15px;
    padding-right: 15px;
}

.errorMessage{
    color: red;
    font-size: 30px;
    text-align: center;

}

.loadingProgressDiv{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 10px;
    font-family: 'roboto';
}

.renderInfo{
    border-radius: 20px;
    background-color: #1C1045;
    font-weight: 400;
    color: #fff;
    padding: 10px;
    width: 100px;
    text-align: center;
    cursor: pointer;
}

.renderInfoButton{
    border-radius: 20px;
    background-color: #1C1045;
    font-weight: 500;
    color: #fff;
    padding: 10px;
    width: 100px;
    height: 35px;
    text-align: center;
    cursor: pointer;
    border: none;
}

.orderModalHeader{
    font-size: 25px;
    font-weight: 700;
    padding-top: 30px;
    color: #1C1045;
    margin: 0;
}

.modalContent{
    font-family: 'roboto';
}

.addArticleButtonDiv {
    display: flex;
    flex-direction: row-reverse;
    margin-top: -20px;
    margin-bottom: 20px;
}

.addArticleButton{
    background-color: #E56F09;
    border-radius: 20px;
    width: 120px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 15px;
    font-weight: 550;
    color: #1C1045;
    border: none;
    cursor: pointer;
}


.cardDivs{
    display: flex;
    flex-wrap: wrap;
}

.cards{
    margin-right: 30px;
    margin-bottom: 20px;
}

.cardStyle{
    width: 400px;
}

.viewMoreButton{
    background-color: #E56F09;
    border-radius: 20px;
    width: 80px;
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 15px;
    font-weight: 550;
    color: #1C1045;
    border: none;
    cursor: pointer;

}

.editArticleButton{
    background-color: #E56F09;
    border-radius: 20px;
    width: 70px;
    padding-top: 4px;
    padding-bottom: 4px;
    font-size: 5px;
    font-weight: 550;
    color: #1C1045;
    border: none;
    cursor: pointer;

}

.editModalTextArea{
    padding-left: 25px;
    padding-top: 25px;
    border-radius: 45px;
    width: 98%;
    border: solid 1px;
    background-color: #f5f5f5;
    resize: none;
    border: none;
    font-size: 15px;
    font-family: 'roboto';
}

.editModalTextArea:focus{
    color: #1C1045;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    outline: none;
}

.modalBody{
    padding-right: 15px;
    padding-left: 15px;
    font-family: 'roboto';
    text-align: justify;
    text-justify: inter-word;
    white-space: pre-wrap;
}

.modalHeader{
    font-size: 25px;
    font-family: 'roboto';
    font-weight: 500;
    margin: 0px;
    padding-right: 15px;
    padding-left: 15px;
}

@media all and (max-width: 700px){
    .addArticleButtonDiv {  
        margin-top: 10px;
    }

    .cardStyle{
        width: 345px;
    }
}