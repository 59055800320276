
.signup{
    display: flex;
}

.formContainer{
    flex: 2;
    height: 100vh;
    color: white;
    padding-top: 20px;
    padding-right: 20px;
    padding-left: 10px;
    background-color: #1C1045;
    overflow: auto;
    margin: 0;
    top: 0; 
    padding-bottom: 20px;
}

.errorMessage{
    color: red;
    font-size: 30px;
    text-align: center;

}
.errorMessage{
    color: red;
    font-size: 30px;
    text-align: center;

}


.straightbar{
    color: #E56F09;
    width: 30px;
    margin-left: 10px;
    margin-right: 10px;
}

.imageCont{
    flex: 3;
    /* max-height: 100vh; */
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}


@media all and (max-width: 700px){
    .imageCont{
        display: none;
    }
}