/* input, select, textarea{
    color: #1C1045;
} */
.profileContainer{
    display: flex;
    overflow-y: hidden;
    max-height: 100vh;
}

.menusidebar{
    flex: 1;
    min-height: 100vh !important;
}

.profileSideFeed{
    flex: 4;
    max-width: 100vw;
    overflow-y: scroll;
}

.updateProfileFeed{
    max-height: 100%;
    padding: 10px;
    padding-top: 10px;
}

/* .goBackButton{
    background-color: #E56F09;
    border-radius: 18px;
    width: 100%;
    margin: auto;
    font-size: 10px;
    height: 30px;
    color: #1C1045;
    border: none;
    cursor: pointer;
} */

.editDetailsActivate{
    background-color: #E56F09;
    border-radius: 20px;
    width: 80%;
    margin: auto;
    padding-top: 5px;
    padding-bottom: 5px;
    align-self: center;
    font-size: 15px;
    height: 30px;
    font-weight: 500;
    color: #1C1045;
    border: none;
    cursor: pointer;
    text-align: center;
}

.editDetailsActivate:disabled{
    cursor: not-allowed;
    opacity: 0.4;
} 

/* .button{
    background-color: #E56F09;
    border-radius: 20px;
    width: 100%;
    margin: auto;
    padding-top: 0px;
    padding-bottom: 20px;
    align-self: center;
    font-size: 18px;
    height: 35px;
    font-weight: 700;
    color: #1C1045;
    border: none;
    cursor: pointer;
    text-align: center;
}

.button:disabled{
    cursor: not-allowed;
    opacity: 0.4;
} */

.successMessage{
    color: #E56F09;
    padding-top: 10px;
    text-align: center;
}

.errorMessage{
    color: red;
    /* font-size: 30px; */
    padding-top: 20px;
    text-align: center;
}

.imageCont{
    flex: 0.5;
    background-repeat: no-repeat;   
    background-size: cover;
    background-position:left;
}

.formInput{
    width: 98%; 
    height: 20px;
    border-radius: 45px;
    border: solid 1px;
    padding-left: 20px;
    background-color: #f5f5f5;
    border: solid 1px;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 20px;
    border: none;
    /* padding-right: 100px; */
    font-size: 20px;
    -webkit-appearance:none;
}

.formInput:focus{
    color:  #1C1045;
}


.addressTextArea{
    padding-left: 25px;
    padding-top: 25px;
    border-radius: 45px;
    width: 98%;
    border: solid 1px;
    background-color: #f5f5f5;
    resize: none;
    border: none;
    font-size: 20px;
    font-family: 'roboto';
}

.addressTextArea:focus{
    color: #1C1045;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    outline: none;
}

@media all and (max-width: 700px){
    .imageCont{
        display: none;
    }
}
