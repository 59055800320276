.addButton{
    background-color: #E56F09;
    border-radius: 20px;
    width: 80px;
    padding-top: 4px;
    padding-bottom: 4px;
    font-size: 15px;
    font-weight: 550;
    color: #1C1045;
    border: none;
    cursor: pointer;
}

.addDiagnosisButton{
  background-color: #fcf7f4;
  border-radius: 20px;
  width: 170px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 18px;
  font-weight: 550;
  color: #1C1045;
  border: none;
  cursor: pointer;

}

.addButton:disabled{
  cursor: not-allowed;
  opacity: 0.4;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.successMessage{
    font-size: 15px;
}

.react-responsive-modal-modal {
    width: 600px;
}

@media all and (max-width: 700px){
  
  .react-responsive-modal-modal {
    width: 300px;
}

}