.statPage{
    display: flex;
    overflow-y: hidden;
    max-height: 100vh;
}

.statSidebar{
    flex: 1;
    min-height: 100vh !important;
}

.statFeed{
    flex: 5;
    max-height: 100vh;
    font-family: 'roboto';
    padding-left: 15px;
    padding-right: 15px;
    overflow-y: scroll;
    
}

.statSubFeed{
    min-height: 52vh;
    overflow-y: scroll;
    padding-left: 10px;
    padding-top: 10px;
    padding-right: 10px;
    max-height: 100vh;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.statSubFeed::-webkit-scrollbar {
    display: none;
}

.dateBox {
    margin: auto;
    width: 80%;
    margin-top: 20px;
    position: relative;
    padding: 5px;
    float: right;
}

.norecord {
    margin: auto;
    font-size: 30px;
    font-family: jost;
    text-align: center;
    padding-top: 20vh;
    padding-bottom: 30vh;
}

.startDate {
    text-align: center;
    margin: 5px;
    /* font-family: jost; */
    font-size: 16px;
    border: 1px solid;
    border-radius: 5px;
    border-color: #737272;
}

.endDate {
    text-align: center;
    margin: 5px;
    /* font-family: jost; */
    font-size: 16px;
    border: 1px solid;
    border-radius: 5px;
    border-color: #737272;
}

.child {
    display: inline-flex;
}

.tableLoadingProgressDiv{
    padding-right: 20px;
}

/* #demo-controlled-open-select {
    padding-right: 100px;
} */

#demo-controlled-open-select-label {
    padding: 10px 0px 10px 10px;
    display: flex;
}

.sticky-table {
    position: inherit;
}

.TableHead {
    background-color: #f2f2f2;
}

.react-date-picker__wrapper {
    display: flex;
    flex-grow: 1;
    flex-shrink: 0;
    /* border: thin solid gray; */
}

.notificationsLoadingProgressDiv{
    position: absolute;
    left: 60%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 10px;
    font-family: 'roboto';
}
.notificationsDiv{
    display: flex;
    flex-wrap: wrap;
    background-color: #f8f8ff;
    cursor: pointer;
    margin-bottom: 5px;
    /* padding-bottom: 10px; */

}

/* .MuiSelect-select.MuiSelect-select {
    padding-right: 24px;
    margin-left: 5px;
    padding-left: 5px;
} */

.nosorder {
    padding-left: 5px;
    bottom: 5px;
}