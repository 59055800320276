
.detailsPage{
    display: flex;
    overflow-y: hidden;
    max-height: 100vh;
    font-family: 'roboto';
}

.menusidebar{
    flex: 1;
    min-height: 100vh !important;
}

.detailsFeed{
    flex: 5;
    max-height: 100vh;
    overflow-y: auto;
    padding-left: 15px;
    padding-right: 15px;
    word-wrap: break-word;
}

.detailsDiv{
    /* padding-left: 10px; */
}

.detailsContainer{
    margin-bottom: 20px;
}

.orderDetailsHeaders{
    font-size: 20px;
    color: #1C1045;
    margin-top: 0px;
    margin-bottom: 0px;
    font-weight: 700;
    font-family: 'roboto';
    padding-bottom: 10px;
}

.detailsPageTopButtonsDiv{
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
    margin-bottom: 10px;
}

.detailsPageButtons{
    background-color: #E56F09;
    border-radius: 20px;
    width: 120px;
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 15px;
    font-weight: 550;
    color: #1C1045;
    border: none;
    cursor: pointer;
    /* margin-right: 10px; */
}

.detailsPageButtons:disabled{
    opacity: 0.4;
    cursor: not-allowed;
}
.viewInvoiceButton{
    background-color: #E56F09;
    border-radius: 20px;
    width: 150px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 18px;
    font-weight: 550;
    color: #1C1045;
    border: none;
    cursor: pointer;

}

.backButton{
    background-color: #E56F09;
    border-radius: 20px;
    width: 80px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 18px;
    font-weight: 550;
    color: #1C1045;
    border: none;
    cursor: pointer;
    margin-right: 10px;
}


.invoiceActionButtons{
    background-color: #E56F09;
    border-radius: 20px;
    width: 150px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 18px;
    font-weight: 550;
    color: #1C1045;
    border: none;
    cursor: pointer;
    margin-right: 10px;
}

.baseSendButton{
    background-color: #E56F09;
    border-radius: 20px;
    width: 120px;
    padding-top: 10px;
    padding-bottom: 9px;
    font-size: 18px;
    font-weight: 550;
    color: #1C1045;
    border: none;
    cursor: pointer;
}

.baseButtonItems{
    display: flex;
    justify-content: center;
}

.baseButtonIcon{
    padding-left: 10px;
    padding-top: -5px;
}

.errorMessage{
    color: red;
    font-size: 30px;
    text-align: center;

}

@media all and (min-width: 700px){
    #invoiceMobile{
        display: none;
    }
}

@media all and (max-width: 700px){
    .topInformation{
        font-size: 15px;
        text-align: left;
    }

    .detailsPageNav{
        display: none;
    }

    .detailsPageButtons{
        background-color: #E56F09;
        border-radius: 20px;
        width: 110px;
        padding-top: 5px;
        padding-bottom: 5px;
        font-size: 13px;
        font-weight: 550;
        color: #1C1045;
        border: none;
        cursor: pointer;
    }
    #invoicePc{
        display: none;
    }
    

    
}