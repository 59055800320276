.verifyOtp{
    display: flex;
}

.OTPformContainer{
    height: 100vh;
    flex: 2;
    color: white;
    overflow: auto;
    padding-right: 20px;
    padding-left: 10px;
    background-color: #1C1045;
}

.resendOTPButton{
    background-color: #1C1045;
    margin-left: 13px;
    margin-top: 10px;
    border: none;
    cursor: pointer;
}

.resendOTPButton:disabled{
    cursor: not-allowed;
    opacity: 0.4;
}

.oTPFormGroup{
    margin: auto;
    width: 85%;
}


.oTPInputGroup{
    margin: auto;
    width: 100%;
    display: flex;
    padding-bottom: 10px;
    flex-flow: row nowrap;
    /* padding-left: 5px; */
    /* justify-content: space-around; */
    justify-content: center;
}


.otpinput{
    width: 200px;
    height:25px;
    text-align:center;
    font-size: 25px;
    border-left: 0px;
    border-right: 0px;
    border-top: 0px;
    border-bottom: 2px solid #E56F09;
    background-color: #1C1045;
    color: #fff;
    padding-bottom: 5px;
}

.otpinput:focus {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    outline: none;
}

.buttonDiv{
    margin: auto;
    display: flex;
    margin-top: 10px;
}

.button{
    background-color: #E56F09;
    border-radius: 20px;
    width: 200px;
    margin: auto;
    justify-content: center;
    padding-top: 10;
    padding-bottom: 10;
    font-size: 20px;
    height: 35px;
    font-weight: 700;
    color: #1C1045;
    border: none;
    cursor: pointer;
    text-align: center;
}

.button:disabled{
    cursor: not-allowed;
    opacity: 0.4;
}

.successMessage{
    color: #E56F09;
    padding-top: 10px;
    text-align: center;
}

.errorMessage{
    color: red;
    /* font-size: 30px; */
    padding-top: 20px;
    text-align: center;
}

.imageCont{
    flex: 3;
    /* max-height: 100vh; */
    background-repeat: no-repeat;   
    background-size: cover;
    background-position: center;
}


@media all and (max-width: 700px){
    
}

@media all and (max-width: 1130px) and (min-width: 700px){
    
    .OTPformContainer{
        flex: 3;
        
    }
    
    .imageCont{
        flex: 2;
    }


}
